<template>
  <div>
    <!-- Collapsible card - for use in old UI layouts during the style transition. This will be removed after the redesign is complete -->
    <vs-collapse transitional-collapse>
      <vs-collapse-item>
        <h5 slot="header" class="in-card-heading__sm">
          <span class="in-card-heading-title">Card Heading</span>
        </h5>
        <vs-card type="transitional-collapsible-card">
          <div>
            <label>Collapsible card main - for use in old UI layouts during the style transition. Will be removed after the redesign is complete</label>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
          </div>
        </vs-card>
      </vs-collapse-item>
    </vs-collapse>
    <br />
  </div>
</template>

<script>
export default {
}
</script>