<template>
  <div>
    <h2 class="font-medium text-xl mt-6 md:mt-0 md:text-2xl lg:text-3xl mb-6 md:mb-8 lg:mb-10">Color Input</h2>
    <div>
      <label class="vs-input--label">Input</label>
      <span class="span-text-validation-danger">Error</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>
