<template>
  <div>
    <label class="label">Special Components</label>
    <label class="label block text-sm">Toolbar</label>
    <div class="mb-16">
      <div class="toolbar-btn-group flex mb-4">
        <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" icon="home">Label</vs-button>
      </div>
      <div class="toolbar-btn-group bottom-right-rounded-toolbar flex">
        <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
        <vs-button class="toolbar-btn" type="border">Text</vs-button>
        <vs-button class="toolbar-btn" type="border">Text</vs-button>
        <vs-button class="toolbar-btn" icon="save">Text</vs-button>
        <vs-button class="toolbar-btn" icon="save">Text</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
