<template>
  <div>
    <!-- reference design system for card types names -->

    <!-- Table card [standard] - Used when the page only contain a table card and no other components -->
    <vs-card type="table">
      <div>
        <label>Table card [standard] - Used when the page only contain a table card and no other components </label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Table card [tabs] - Used when there is a tab component on top of the table card -->
    <vs-card type="tab-table">
      <div>
        <label>Table card [tabs] - Used when there is a tab component on top of the table card</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Page card [standard] - Used when the page only contain a card and no other components  -->
    <vs-card type="page">
      <div>
        <label>Page card [standard] - Used when the page only contain a card and no other components</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Page card [toolbar] - Used when there is a button toolbar on top of the card -->
    <vs-card type="toolbar-page">
      <div>
        <label>Page card [toolbar] - Used when there is a button toolbar on top of the card</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Page card [left] -->
    <vs-card type="left-page">
      <div>
        <label>Page card [left]</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Page card [sidebar] - Used when there is a sidebar component to the left of the card -->
    <vs-card type="sidebar-page">
      <div>
        <label>Page card [sidebar] - Used when there is a sidebar component to the left of the card</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Page card right [toolbar] - for use with button toolbar and sidebar components -->
    <vs-card type="toolbar-sidebar-page">
      <div>
        <label>Table card [tabs] - Used when there is a tab component on top of the table card</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />

    <!-- Collapsible card main - for use in old UI layouts during the style transition. Will be removed after the redesign is complete -->
    <vs-card type="transitional-collapsible-card">
      <div>
        <label>Table card [tabs] - Used when there is a tab component on top of the table card</label>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
      </div>
    </vs-card>
    <br />
  </div>
</template>

<script>
import ScrollableTabs from "./ScrollableTabs.vue"
export default {
  components: { ScrollableTabs }
}
</script>