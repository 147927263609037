<template lang="html">
  <div class="">
    <h2 class="font-medium text-xl mt-6 md:mt-0 md:text-2xl lg:text-3xl mb-6 md:mb-8 lg:mb-10">Input fields</h2>

    <label class="label block">Base Text Input</label>
    <div class="flex justify-between items-start flex-wrap">
      <vs-input label='Label' placeholder="Required" v-model="value1"/>
      <vs-input label='Label' placeholder="Required" :danger="true" danger-text="Error Message" v-model="value1"/>
      <vs-input disabled="true" class="isDisabled" label="Label" v-model="value5"/>
      <vs-input class="isReadonly" readonly="true" label='Label' v-model="value2"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input label='Label' placeholder="Required" :value="'Field content'" />
      <vs-input label='Label' placeholder="Required" :danger="true" danger-text="Error Message"  :value="'Field content'" />
      <vs-input disabled="true" class="isDisabled" label="Label" :value="'Field content'" />
      <vs-input class="isReadonly" readonly="true" label='Label' :value="'Field content'"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input  placeholder="Required" class="no-label-input" v-model="value1"/>
      <vs-input  placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value1"/>
      <vs-input disabled="true"  class="isDisabled no-label-input" v-model="value5"/>
      <vs-input class="isReadonly no-label-input" readonly="true"  v-model="value2"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input placeholder="Required" class="no-label-input" :value="'Field content'"/>
      <vs-input placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message"  :value="'Field content'" />
      <vs-input disabled="true" class="isDisabled no-label-input" :value="'Field content'" />
      <vs-input class="isReadonly no-label-inputs" readonly="true" :value="'Ignore this!'"/>
    </div>

    <!-- UNIT INPUT -->
    <label class="label block">Unit Input</label>
    <div class="flex justify-between items-start">
      <vs-input label='Label' placeholder="Required" v-model="value1" class="hasUnit"  description-text="unit"/>
      <vs-input label='Label' placeholder="Required" :danger="true" class="hasUnit" description-text="unit" danger-text="Error Message" v-model="value1"/>
      <vs-input disabled="true" class="isDisabled hasUnit" label="Label" v-model="value5"/>
      <vs-input class="isReadonly hasUnit" readonly="true" label='Label' v-model="value2"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input label='Label' placeholder="Required" :value="'Field content'" class="hasUnit"  description-text="unit"/>
      <vs-input label='Label' placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit" description-text="unit" danger-text="Error Message" />
      <vs-input disabled="true" class="isDisabled hasUnit" :value="'Field content'" label="Label" />
      <vs-input class="isReadonly hasUnit" readonly="true" :value="'Field content'" label='Label' />
    </div>

    <div class="flex justify-between items-start">
      <vs-input placeholder="Required" v-model="value1" class="hasUnit no-label-input"  description-text="unit"/>
      <vs-input placeholder="Required" :danger="true" class="hasUnit no-label-input" description-text="unit" danger-text="Error Message" v-model="value1"/>
      <vs-input disabled="true" class="isDisabled hasUnit no-label-input" v-model="value5"/>
      <vs-input class="isReadonly hasUnit" readonly="true" v-model="value2"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input placeholder="Required" :value="'Field content'" class="hasUnit no-label-input"  description-text="unit" />
      <vs-input placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit no-label-input" description-text="unit" danger-text="Error Message"/>
      <vs-input disabled="true" :value="'Field content'" class="isDisabled hasUnit no-label-input" description-text="unit"/>
      <vs-input class="isReadonly hasUnit" :value="'Field content'" readonly="true" description-text="unit"/>
    </div>

    <label class="label block">Currency Left Input</label>
    <div class="flex justify-between items-start">
      <vs-input label='Label' placeholder="Required" v-model="value1" class="hasUnit hasUnitLeft"  description-text="$"/>
      <vs-input label='Label' placeholder="Required" :danger="true" class="hasUnit hasUnitLeft" description-text="$" danger-text="Error Message" v-model="value1"/>
      <vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft" description-text="$" label="Label" v-model="value5"/>
      <vs-input class="isReadonly hasUnit hasUnitLeft" readonly="true" description-text="$" label='Label' v-model="value2"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input label='Label' placeholder="Required" :value="'Field content'" class="hasUnit hasUnitLeft"  description-text="$"/>
      <vs-input label='Label' placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit hasUnitLeft" description-text="$" danger-text="Error Message"/>
      <vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft" :value="'Field content'" description-text="$" label="Label"/>
      <vs-input class="isReadonly hasUnit hasUnitLeft" :value="'Field content'" readonly="true" description-text="$" label='Label'/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input placeholder="Required" v-model="value1" class="hasUnit hasUnitLeft no-label-input"  description-text="$"/>
      <vs-input placeholder="Required" :danger="true" class="hasUnit hasUnitLeft no-label-input" description-text="$" danger-text="Error Message" v-model="value1"/>
      <vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft no-label-input" description-text="$" v-model="value5"/>
      <vs-input class="isReadonly hasUnit hasUnitLeft no-label-input" readonly="true" description-text="$" v-model="value2"/>
    </div>

    <div class="flex justify-between items-start">
      <vs-input placeholder="Required" :value="'Field content'" class="hasUnit hasUnitLeft no-label-input"  description-text="$"/>
      <vs-input placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit hasUnitLeft no-label-input" description-text="$" danger-text="Error Message"/>
      <vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft no-label-input" :value="'Field content'" description-text="$"/>
      <vs-input class="isReadonly hasUnit hasUnitLeft no-label-input" :value="'Field content'" readonly="true" description-text="$"/>
    </div>

    <!--INPUT WITH ICON -->
    <label class="label block">Input with Icon</label>
    <div class="flex justify-between items-start">
      <vs-input label='test' icon="search" icon-after="true" placeholder="Search"  v-model="value5"/>
      <vs-input label='test' icon="search" icon-after="true" placeholder="Search" :danger="true" danger-text="Error Message" v-model="value5"/>
      <vs-input label='test' icon="search" icon-after="true" placeholder="Search" disabled="true" class="isDisabled" v-model="value5"/>
    </div>
    <div class="flex justify-between items-start">
      <vs-input icon="search" icon-after="true" placeholder="Search" class="no-label-input" v-model="value5"/>
      <vs-input icon="search" icon-after="true" placeholder="Search" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value5"/>
      <vs-input icon="search" icon-after="true" placeholder="Search" disabled="true" class="isDisabled no-label-input" v-model="value5"/>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      value1:'',
      value2:'',
      value3:'',
      value4:'',
      value5:'',
      value6:'Field Content',
      value7:'',
      value8: ''
    }
  },
  methods: {
    clickIcon: () => {
      console.log('clicked it')
    },
  }
}
</script>