<template>
  <div id="design-system" class="mx-auto py-8" style="max-width: 1440px">
    <div> New Design System Components!</div>

    <vs-button @click="messagesOpen=true">Open Messages Popup</vs-button>
    <vs-popup title="Messages" :active.sync="messagesOpen">
      <messages></messages>
    </vs-popup>
    <hr class="mt-2 mb-4" />
    <vs-button @click="layoutCardsOpen=true">Open Layout Cards Popup</vs-button>
    <vs-popup title="Layout Cards" :active.sync="layoutCardsOpen">
      <div class="bg-page-background p-2">
        <collapsible-card></collapsible-card>
        <layout-cards></layout-cards>
      </div>
    </vs-popup>
    <hr class="mt-2 mb-4" />

    <vs-button @click="headingsPopupOpen=true">Open Headings Popup</vs-button>
    <vs-popup title="Headings" :active.sync="headingsPopupOpen">
      <headings></headings>
    </vs-popup>
    <hr class="mt-2 mb-4" />
    <hr class="mt-2 mb-4" />
    <popup></popup>
    <hr class="mt-2 mb-4 line-hr" />
    <vs-button @click="inputFieldsPopupOpen=true">Open Input Fields Popup</vs-button>
    <vs-popup title="Input Fields" :active.sync="inputFieldsPopupOpen">
      <select-field></select-field>
      <hr class="mt-2 mb-4 line-hr" />
      <input-fields></input-fields>
    </vs-popup>

    <hr class="mt-2 mb-4 line-hr" />
    <vs-button @click="buttonsPopupOpen=true">Open Buttons Popup</vs-button>
    <vs-popup title="Buttons" :active.sync="buttonsPopupOpen">
      <buttons></buttons>
    </vs-popup>
    
    <toolbar></toolbar>
    <hr class="mt-2 mb-4 line-hr" />
    <scrollable-tabs></scrollable-tabs>
    <hr class="mt-2 mb-4 line-hr" />
    <hr class="mt-2 mb-4 line-hr" />
  </div>
</template>

<script>
import Buttons from "../components/design-sys-components/Buttons";
import CollapsibleCard from "../components/design-sys-components/CollapsibleCard";
import ColorInput from "../components/design-sys-components/ColorInput";
import Headings from "../components/design-sys-components/Headings";
import InputFields from "../components/design-sys-components/InputFields";
import NewTextarea from "../components/design-sys-components/Textarea";
import ScrollableTabs from "../components/design-sys-components/ScrollableTabs";
import SelectField from "../components/design-sys-components/SelectField.vue";
import Sidebar from "../components/design-sys-components/Sidebar";
import Toolbar from "../components/design-sys-components/Toolbar";
import Popup from "../components/design-sys-components/Popup";
import LayoutCards from "../components/design-sys-components/LayoutCards";
import Messages from "../components/design-sys-components/Messages";

export default {
  components: {
    Buttons,
    CollapsibleCard,
    ColorInput,
    Headings,
    InputFields,
    NewTextarea,
    ScrollableTabs,
    SelectField,
    Sidebar,
    Toolbar,
    Popup,
    LayoutCards,
    Messages,
  },
  mounted() {
    document. body. classList. add('design-system');
  },
  data(){
    return {
    inputFieldsPopupOpen: false,
    buttonsPopupOpen: false,
    headingsPopupOpen: false,
    layoutCardsOpen: false,
    messagesOpen: false,
    }
  }
}
</script>