<template lang="html">
  <div>
    <label class="block">POPUPS</label>
    <vs-button @click="defaultOpen=true" color="primary">Open Default/Regular popup</vs-button>
    <vs-popup title="Heading"  :active.sync="defaultOpen">
      <p class="popup-message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn>Button Text</vs-button>
        <vs-button @click="defaultOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- close icon hidden -->
    <vs-button @click="closeHiddenOpen=true" color="primary">Open Close icon hidden popup</vs-button>
    <vs-popup title="Warning heading" class="close-hidden" :active.sync="closeHiddenOpen">
      <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn>Save</vs-button>
        <vs-button @click="closeHiddenOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- success popup -->
    <vs-button @click="successOpen=true">Open Success popup</vs-button>
    <vs-popup title="Success heading" header-icon="success" class="close-icon-hidden" :active.sync="successOpen">
      <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn>Save</vs-button>
        <vs-button @click="successOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- error popup -->
    <vs-button @click="errorOpen=true">Open Error popup</vs-button>
    <vs-popup title="Error heading" header-icon="error" :active.sync="errorOpen">
      <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn>Save</vs-button>
        <vs-button @click="errorOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- warning popup -->
    <vs-button @click="warningOpen=true">Open Warning popup</vs-button>
    <vs-popup title="Warning heading" header-icon="warning" class="close-icon-hidden" :active.sync="warningOpen">
      <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn>Save</vs-button>
        <vs-button @click="warningOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultOpen:false,
      closeHiddenOpen: false,
      successOpen: false,
      errorOpen: false,
      warningOpen: false,
    }
  }
}
</script>