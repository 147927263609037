<template>
  <div>
    <vs-textarea counter="250" maxlength="250" row="3" name="bodyText" v-model="customTextBlockData.body" id="customer-message" @input="auto_grow('customer-message')"></vs-textarea>
  </div>
</template>

<script>
export default {
  methods: {
    auto_grow(elem) {
      var element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
      this.$refs.customerMessage.maxHeight = "none !important";
    }
  },
 updated() {
   this.auto_grow('customer-message');
 }
};
</script>