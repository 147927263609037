<template>
  <div class="scrollable-tabs bg-page-background">
    <vue-horizontal class="scrollable-tabs-container" :displacement="0.5" :button-between="false">
      <template v-slot:btn-prev>
        <vs-button type="flat" icon="chevron_left"></vs-button>
      </template>

      <template v-slot:btn-next>
        <vs-button type="flat" icon="chevron_right"></vs-button>
      </template>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab tab--selected">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
          <input class="tab-label-input" id='test-width' value="Tab Label" v-on:keyup="auto_expand('sdf')"/>
        </span>
        <vs-dropdown vs-trigger-click class="tab-actions">
          <vs-button class="tab-action-btn" type="flat" icon="more_vert"></vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item>
              <i class="material-icons tab-action-option-icon"> border_color </i>
              <span class="tab-action-option-label">option 1</span>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <i class="material-icons tab-action-option-icon"> border_color </i>
              <span class="tab-action-option-label">option 1</span>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <i class="material-icons tab-action-option-icon"> border_color </i>
              <span class="tab-action-option-label">option 1</span>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <i class="material-icons tab-action-option-icon"> border_color </i>
              <span class="tab-action-option-label">option 1</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <div class="tab tab--selected">
        <span class="tab-label-container isEditMode" id="tab-label-container-4">
          <span class="tab-label">{{text}}</span>
          <input class="tab-label-input" id='test-width' value="text" v-on:keyup="auto_expand('sdf')" v-model="text"/>
        </span>
        <vs-dropdown vs-trigger-click class="tab-actions">
          <vs-button class="tab-action-btn" type="flat" icon="more_vert"></vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item>
              <i class="material-icons tab-action-option-icon"> mood </i>
              <span class="tab-action-option-label">option 1</span>
            </vs-dropdown-item>
            <vs-dropdown-item>option 2</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <div class="tab tab--selected">
        <span class="tab-label-container" id="tab-label-container-4">
          <span class="tab-label">Tab Label</span>
          <input class="tab-label-input" id='test-width' value="text" v-on:keyup="auto_expand('sdf')" v-model="text"/>
        </span>
        <vs-dropdown vs-trigger-click class="tab-actions">
          <vs-button class="tab-action-btn" type="flat" icon="more_vert"></vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item>
              <i class="material-icons tab-action-option-icon"> mood </i>
              <span class="tab-action-option-label">option 1</span>
            </vs-dropdown-item>
            <vs-dropdown-item>option 2</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>

      <div class="tab">
        <span class="tab-label-container">
          <span class="tab-label">Tab Label</span>
        </span>
      </div>
    </vue-horizontal>
    <vs-button radius type="flat" icon="add" class="add-tab-btn"></vs-button>
  </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal';

export default {
  components: { VueHorizontal },
  data () {
    return {
      text: '!',
    }
  },
  methods: {
    auto_expand() {
      let element = document.getElementById("tab-label-container-4");
      let label = element.children[0];
      let input = element.children[1];

      input.style.width =  "5px";
      input.style.width =  (label.offsetWidth + 20)+"px";
    },
  },
  mounted() {
    this.auto_expand();
  },
  updated() {
    this.auto_expand();
  }
}
</script>