<template>
  <div>
    <div>
      <!-- System messages -->
      <vs-button @click="showSuccessMessage">Success: System Message</vs-button>
      <vs-button @click="showErrorMessage">Error: System Message</vs-button>
      <vs-button @click="showWarningMessage">Warning: System Message</vs-button>
    </div>

    <br />
    <!-- In card messages -->
    <div class="w-full">
      <vs-alert color="danger" icon="required_prop">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
      </vs-alert>
      <br />

      <vs-alert color="success" icon="check_circle_outline">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
      </vs-alert>
      <br />

      <vs-alert color="warning" icon="error_outline">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
      </vs-alert>
      <br />

      <vs-alert color="warning" icon="error_outlined" validation-error>Please populate all required fields before proceeding.</vs-alert>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
      showSuccessMessage(title = 'Success', message='Success') {
        this.$toast.success(<div class="container"><h4>Success heading</h4></div>, {
          position: "bottom-left",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
        message: message,
      });
    },
    showWarningMessage(title = 'Warning', message='Warning') {
        this.$toast.warning(<div class="container"><h4>Warning heading</h4><p>Waning text lorem ipsum dolor lorem ipsum dolor.</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "error_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
      });
    },
    showErrorMessage(title = 'Error', message='Error') {
        this.$toast.error(<div class="container"><h4>Error heading</h4><p>Error text lorem ipsum dolor lorem ipsum dolor.</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons-outlined",
            iconChildren: "dangerous",
            iconTag: "span"
          },
          rtl: false,
          message: message,
      });
    },
  }
}
</script>