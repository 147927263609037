<template lang="html">
  <div>
    <h2 class="font-medium text-xl mt-6 md:mt-0 md:text-2xl lg:text-3xl mb-6 md:mb-8 lg:mb-10">Select fields</h2>
    <div class="flex justify-between items-start">
      <vs-select placeholder='Required' label="Label" v-model="select1">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
      </vs-select>

      <vs-select placeholder='Required' :danger="true" danger-text= "Error message" label="Label" v-model="select1">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
      </vs-select>

      <vs-select placeholder='Required' :disabled="true" label="Label" v-model="select1">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
      </vs-select>
    </div>

    <div class="flex justify-between items-start">
      <vs-select placeholder='Required' class="no-label-select" v-model="select1">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
      </vs-select>

      <vs-select placeholder='Required' :danger="true" danger-text= "Error message" class="no-label-select" v-model="select1">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
      </vs-select>

      <vs-select placeholder='Required' :disabled="true" class="no-label-select" v-model="select1">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
      </vs-select>
    </div>
  </div>

    
</template>

<script>
export default {
  data(){
    return {
      select1Normal:'',
      options1:[
        {text:'IT',value:0},
        {text:'Blade Runner',value:2},
        {text:'Thor Ragnarok',value:3},
        {text:'Thor Ragnarok',value:3},
        {text:'Thor Ragnarok',value:3},
        {text:'Field content',value:3},
      ]
    }
  },
  methods:{
    prueba(){
      console.log("prueba de focsu");
    }
  }
}
</script>