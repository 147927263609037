<template>
  <div>
    <h2 class="font-medium text-xl mt-6 md:mt-0 md:text-2xl lg:text-3xl mb-6 md:mb-8 lg:mb-10">Buttons</h2>
    <div>
      <!-- Large SIZE -->
      <h3>Large</h3>
      <label class="label block">Large - Base Button</label>
      <div class="flex justify-around mb-16">
        <vs-button color="primary" size="large">Label</vs-button>
        <vs-button color="primary" loading="true" size="large">Base</vs-button>
        <vs-button color="primary" disabled="true" size="large">Disabled</vs-button>
      </div>

      <!-- LEFT ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-16">
        <vs-button icon="add" size="large">Label</vs-button>
        <vs-button icon="add" size="large" loading="true">Label</vs-button>
        <vs-button icon="add" size="large" disabled>Label</vs-button>
      </div>

      <!-- LEFT ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-16">
        <vs-button icon="add" size="large">Click</vs-button>
        <vs-button icon="add" size="large" loading="true">Label</vs-button>
        <vs-button icon="add" size="large" disabled>Label</vs-button>
      </div>

      <!-- RIGHT ICON -->
      <label class="label block">Primary - Right Icon</label>
      <div class="mb-16">
        <div class="flex justify-around mb-8">
          <vs-button icon-right="true" icon="send" size="large" class="icon-small">Label</vs-button>
          <vs-button icon-right="true" icon="send" size="large" loading="true">Label</vs-button>
          <vs-button icon-right="true" icon="send" size="large" class="icon-small" disabled>Label</vs-button>
        </div>
      </div>

      <!-- OUTLINED/BORDER ONLY -->
      <!-- PRIMARY-BASE -->
      <label class="label block" >Primary - Base Button</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" size="large">Label</vs-button>
        <vs-button type="border" size="large" loading="true">Label</vs-button>
        <vs-button type="border" size="large" disabled>Label</vs-button>
      </div>

      <!-- PRIMARY-LEFT-ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" icon="add" size="large">Label</vs-button>
        <vs-button type="border" icon="add" size="large" loading="true">Label</vs-button>
        <vs-button type="border" icon="add" size="large" disabled>Label</vs-button>
      </div>

      <!-- PRIMARY-RIGHT-ICON -->
      <label class="label block">Primary - Right Icon</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small">Label</vs-button>
        <vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small" loading="true">Label</vs-button>
        <vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small" disabled>Label</vs-button>
      </div>
    </div>
    
    <!-- MEDIUM SIZE -->
    <div>
      <h3>medium</h3>
      <label class="label block">Primary - Base Button</label>
      <div class="flex justify-around mb-16">
        <vs-button color="primary">Label</vs-button>
        <vs-button color="primary" loading="true">Base</vs-button>
        <vs-button color="primary" disabled="true">Disabled</vs-button>
      </div>

      <!-- LEFT ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-16">
        <vs-button icon="add">Click</vs-button>
        <vs-button icon="add" loading="true">Label</vs-button>
        <vs-button icon="add" disabled>Label</vs-button>
      </div>

      <!-- RIGHT ICON -->
      <label class="label block">Primary - Right Icon</label>
      <div class="mb-16">
        <div class="flex justify-around mb-8">
          <vs-button icon-right="true" icon="send" class="icon-small">Label</vs-button>
          <vs-button icon-right="true" icon="send" loading="true">Label</vs-button>
          <vs-button icon-right="true" icon="send" class="icon-small" disabled>Label</vs-button>
        </div>
      </div>

      <!-- OUTLINED/BORDER ONLY -->
      <!-- PRIMARY-BASE -->
      <label class="label block" >Primary - Base Button</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border">Label</vs-button>
        <vs-button type="border" loading="true">Label</vs-button>
        <vs-button type="border" disabled>Label</vs-button>
      </div>

      <!-- PRIMARY-LEFT-ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" icon="add">Label</vs-button>
        <vs-button type="border" icon="add" loading="true">Label</vs-button>
        <vs-button type="border" icon="add" disabled>Label</vs-button>
      </div>

      <!-- PRIMARY-RIGHT-ICON -->
      <label class="label block">Primary - Right Icon</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" icon-right="true" icon="send" class="icon-small" >Label</vs-button>
        <vs-button type="border" icon-right="true" icon="send" loading="true">Label</vs-button>
        <vs-button type="border" icon-right="true" icon="send" class="icon-small" disabled>Label</vs-button>
      </div>
    </div>

    <div>
      <!-- SMALL SIZE -->
      <h3>Small</h3>
      <label class="label block">Large - Base Button</label>
      <div class="flex justify-around mb-16">
        <vs-button color="primary" size="small">Label</vs-button>
        <vs-button color="primary" loading="true" size="small">Base</vs-button>
        <vs-button color="primary" disabled="true" size="small">Disabled</vs-button>
      </div>

      <!-- LEFT ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-16">
        <vs-button icon="add" size="small">Click</vs-button>
        <vs-button icon="add" size="small" loading="true">Label</vs-button>
        <vs-button icon="add" size="small" disabled>Label</vs-button>
      </div>

      <!-- LEFT ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-16">
        <vs-button icon="add" size="small">Click</vs-button>
        <vs-button icon="add" size="small" loading="true">Label</vs-button>
        <vs-button icon="add" size="small" disabled>Label</vs-button>
      </div>

      <!-- RIGHT ICON -->
      <label class="label block">Primary - Right Icon</label>
      <div class="mb-16">
        <div class="flex justify-around mb-8">
          <vs-button icon-right="true" icon="send" size="small" class="icon-small">Label</vs-button>
          <vs-button icon-right="true" icon="send" size="small" class="icon-small" loading="true">Label</vs-button>
          <vs-button icon-right="true" icon="send" size="small" class="icon-small" disabled>Label</vs-button>
        </div>
      </div>

      <!-- OUTLINED/BORDER ONLY -->
      <!-- PRIMARY-BASE -->
      <label class="label block" >Primary - Base Button</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" size="small">Label</vs-button>
        <vs-button type="border" size="small" loading="true">Label</vs-button>
        <vs-button type="border" size="small" disabled>Label</vs-button>
      </div>

      <!-- PRIMARY-LEFT-ICON -->
      <label class="label block">Primary - Left Icon</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" icon="add" size="small">Label</vs-button>
        <vs-button type="border" icon="add" size="small" loading="true">Label</vs-button>
        <vs-button type="border" icon="add" size="small" disabled>Label</vs-button>
      </div>

      <!-- PRIMARY-RIGHT-ICON -->
      <label class="label block">Primary - Right Icon</label>
      <div class="flex justify-around mb-8">
        <vs-button type="border" icon-right="true" icon="send" class="icon-small" size="small">Label</vs-button>
        <vs-button type="border" icon-right="true" icon="send" size="small" loading="true">Label</vs-button>
        <vs-button type="border" icon-right="true" icon="send" class="icon-small" size="small" disabled>Label</vs-button>
      </div>
    </div>

    <!-- Toolbar Button -->
    <label class="label">Toolbar Button</label>
    <div class="mb-16">
      <div class="flex justify-around mb-8">
        <!-- default filled button -->
        <vs-button class="toolbar-btn">Label</vs-button>
        <vs-button class="toolbar-btn" loading="true">Label</vs-button>
        <vs-button class="toolbar-btn" disabled>Label</vs-button>
      </div>

      <div class="flex justify-around mb-8">
        <!-- default filled button -->
        <vs-button class="toolbar-btn" icon="save">Label</vs-button>
        <vs-button class="toolbar-btn" icon="save" loading="true">Label</vs-button>
        <vs-button class="toolbar-btn" icon="save" disabled>Label</vs-button>
      </div>

      <div class="flex justify-around mb-8">
        <vs-button class="toolbar-btn" type="border" icon="home" icon-right="true">Label</vs-button>
        <vs-button class="toolbar-btn" type="border" icon="home" icon-right="true" loading="true">Label</vs-button>
        <vs-button class="toolbar-btn" type="border" icon="home" icon-right="true" disabled="true">Label</vs-button>
      </div>
    </div>
    <div class="mb-16">
      <!-- Flat/Link Buttons -->
      <!-- Base -->
      <label class="label">Base Link Button</label>
      <div class="flex justify-around mb-8">
        <vs-button type="flat">Flat button</vs-button>
        <vs-button type="flat" disabled="true">Flat button</vs-button>
      </div>

      <!-- Left Icon Buttons -->
      <label class="label">Left Icon Link Button</label>
      <div class="flex justify-around mb-8">
        <vs-button type="flat" icon="add">Flat button</vs-button>
        <vs-button type="flat" icon="add" disabled="true">Flat button</vs-button>
      </div>

      <!-- Right Icon Buttons -->
      <label class="label">Right Icon Link Button</label>
      <div class="flex justify-around mb-8">
        <vs-button type="flat" icon="add" icon-right="true">Flat button</vs-button>
        <vs-button type="flat" icon="add" icon-right="true" disabled="true">Flat button</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>