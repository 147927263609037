<template>
  <div>
    <!-- IN CARD HEADINGS -->
    <label>IN-CARD HEADING: LARGE</label>
    <hr class="mt-2 mb-4 line-hr" />
    <!-- simple heading -->
    <h4 class="in-card-heading__lg">
      <span class="in-card-heading-title">Heading: Simple</span>
    </h4>
    <hr class="heading-divider"/>
    <br />

    <!-- heading with tooltip-->
    <h4 class="in-card-heading__lg">
      <span class="in-card-heading-title">Heading: With tooltip</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
    </h4>
    <hr class="heading-divider"/>
    <br />

    <!-- heading without divider-->
    <h4 class="in-card-heading__lg">
      <span class="in-card-heading-title">Heading: without divider</span>
    </h4>
    <br />

    <!-- heading with tooltip and without divider-->
    <h4 class="in-card-heading__lg">
      <span class="in-card-heading-title">Heading: with tooltip and no divider</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
    </h4>
    <br />

    <!-- COLLAPSIBLE CARD/ACCORDION HEADER-->
    <vs-collapse>
     <vs-collapse-item>
        <div slot="header">
          <h4 slot="header" class="in-card-heading__lg">
            <span class="in-card-heading-title">Heading: collapsible</span>
          </h4>
          <hr class="heading-divider"/>
        </div>
        <span>card open</span>
     </vs-collapse-item>
    </vs-collapse>
    <br />

    <vs-collapse>
     <vs-collapse-item>
        <div slot="header">
          <h4 slot="header" class="in-card-heading__lg">
            <span class="in-card-heading-title">Heading: collapsible with tooltip</span>
            <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
          </h4>
          <hr class="heading-divider"/>
        </div>
        <span>card open</span>
     </vs-collapse-item>
    </vs-collapse>
    <br />

    <!-- heading with link -->
    <h4 class="in-card-heading__lg">
      <span class="in-card-heading-title">Heading: with link</span>
      <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
    </h4>
    <hr class="heading-divider"/>
    <br />

    <h4 class="in-card-heading__lg">
      <span class="in-card-heading-title">Heading: with link and tooltip</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
      <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
    </h4>
    <hr class="heading-divider"/>
    <br />

    
    <!-- heading with icon -->
    <h4 class="in-card-heading__lg">
      <vs-icon class="in-card-heading-icon">grade</vs-icon>
      <span class="in-card-heading-title">Heading: with icon</span>
    </h4>
    <hr class="heading-divider"/>
    <br />
    
    <h4 class="in-card-heading__lg">
      <vs-icon class="in-card-heading-icon">grade</vs-icon>
      <span class="in-card-heading-title">Heading: with icon tooltip</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
    </h4>
    <hr class="heading-divider"/>
    <br />
    <br />

    <!-- END OF COLLAPSIBLE CARD/ACCORDION HEADER -->
    <!-- Small heading -->
    <label>IN-CARD HEADING: SMALL</label>
    <hr class="mt-2 mb-4 line-hr" />
    <!-- simple heading -->
    <h5 class="in-card-heading__sm">
      <span class="in-card-heading-title">Heading: simple</span>
    </h5>
    <hr class="heading-divider"/>
    <br />

    <!-- heading with tooltip-->
    <h5 class="in-card-heading__sm">
      <span class="in-card-heading-title">Heading: with tooltip</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
    </h5>
    <hr class="heading-divider"/>
    <br />

    <!-- heading without divider-->
    <h5 class="in-card-heading__sm">
      <span class="in-card-heading-title">Heading: without divider</span>
    </h5>
    <br />

    <!-- heading with tooltip and without divider-->
    <h5 class="in-card-heading__sm">
      <span class="in-card-heading-title">Heading: with tooltip and no divider</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
    </h5>
    <br />

    <!-- COLLAPSIBLE CARD/ACCORDION HEADER-->
    <vs-collapse>
     <vs-collapse-item>
        <div slot="header">
          <h5 slot="header" class="in-card-heading__sm">
            <span class="in-card-heading-title">Heading: collapsible</span>
          </h5>
          <hr class="heading-divider"/>
        </div>
        <span>card open</span>
     </vs-collapse-item>
    </vs-collapse>
    <br />

    <vs-collapse>
     <vs-collapse-item>
        <div slot="header">
          <h5 slot="header" class="in-card-heading__sm">
            <span class="in-card-heading-title">Heading: collapsible with tooltip</span>
            <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
          </h5>
          <hr class="heading-divider"/>
        </div>
        <span>card open</span>
     </vs-collapse-item>
    </vs-collapse>
    <br />

    <!-- heading with link -->
    <h5 class="in-card-heading__sm">
      <span class="in-card-heading-title">Heading: with link</span>
      <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
    </h5>
    <hr class="heading-divider"/>
    <br />

    <h5 class="in-card-heading__sm">
      <span class="in-card-heading-title">Heading: with link and tooltip</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
      <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
    </h5>
    <hr class="heading-divider"/>
    <br />

    <!-- heading with icon -->
    <h5 class="in-card-heading__sm">
      <vs-icon class="in-card-heading-icon">grade</vs-icon>
      <span class="in-card-heading-title">Heading: with icon</span>
    </h5>
    <hr class="heading-divider"/>
    <br />
    
    <h5 class="in-card-heading__sm">
      <vs-icon class="in-card-heading-icon">grade</vs-icon>
      <span class="in-card-heading-title">Heading: with icon and tooltip</span>
      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
    </h5>
    <hr class="heading-divider"/>
    <br />
    <!-- END OF IN CARD HEADINGS -->
  </div>
</template>

<script>
export default {
}
</script>